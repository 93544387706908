import { Shape } from "../../../components/shapes";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { Grid } from "../../blocs";
import { ClassnameTypeType } from "../../buttons";
import { IconButtonIcons, IconList } from "../../icons";

type Props = {
  children: any;
  size?: number;
  icon?: IconList;
  iconStyle?: ClassnameTypeType;
  className?: string;
  iconColor?: CssVariablesType;
  shapeColor?: CssVariablesType;
};

export function H1({ children, size = 20, icon, iconColor = "text-soft", shapeColor = "blue-background", className = "" }: Props) {
  return (
    <Grid mediaQuery={0} template={icon ? `auto 1fr` : "1fr"} alignItems="center" className="mb-1 mt-4">
      {icon && (
        <Shape form="circle" color={shapeColor}>
          <IconButtonIcons icon={icon} size={size} color={iconColor} />
        </Shape>
      )}
      <h1 className={"mt-0 mb-0 " + className}>{children}</h1>
    </Grid>
  );
}
