import classNames from "classnames";
import { Shape } from "../../../components/shapes";
import { Grid } from "../../blocs";

import { HTMLAttributes, useEffect, useState } from "react";
import { computeIconStyle, IconStyleType } from "..";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { IconButtonIcons, IconList } from "../../icons";

type Props = {
  children?: any;
  size?: number;
  icon?: IconList | any;
  iconColor?: CssVariablesType;
  shapeColor?: CssVariablesType;
  iconStyle?: IconStyleType;
  className?: string;
  marginTop?: boolean;
  style?: {};
  props?: HTMLAttributes<HTMLDivElement>;
};

export function H4({ children, size = 20, icon, iconColor = "text-soft", shapeColor = "blue-background", iconStyle = null, className = "", props }: Props) {
  const [computedShapeColor, setComputedShapeColor] = useState(null);
  const [computedIconColor, setComputedIconColor] = useState(null);

  useEffect(() => {
    if (iconStyle) {
      let computed = computeIconStyle(iconStyle);
      setComputedShapeColor(computed.shapeColor);
      setComputedIconColor(computed.iconColor);
    }
  }, [iconStyle]);

  return (
    <Grid space={10} mediaQuery={0} template={icon ? `auto 1fr` : "1fr"} alignItems="center" className={classNames("mb-1", className)} {...props}>
      {icon && (
        <Shape form="circle" color={computedShapeColor ?? shapeColor}>
          <IconButtonIcons icon={icon} size={size} color={computedIconColor ?? iconColor} />
        </Shape>
      )}
      {children && <h4 className="mt-0 mb-0">{children}</h4>}
    </Grid>
  );
}
