import { AxiosInstance } from "axios";
import { QuestionAttachmentType, QuestionExpertAttachmentType } from "../../../../utilities/redux/types";
import { useEffect, useState } from "react";
import { CircleLoader } from "../../loaders";
import * as XLSX from 'xlsx';

type Props = {
  file: QuestionAttachmentType | QuestionExpertAttachmentType;
  app: "company" | "expert" | "admin";
  senderType: "" | "_from_company" | "_from_expert";
  fetcher: AxiosInstance;
};

export function XlsxPreview({ file, app, senderType = "", fetcher }: Props) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFile = (signal: AbortSignal) => {
    setLoading(true);
    fetcher
      .get(`${app}/questionAttachment/questionAttachment${senderType}/${file?.id}`, { responseType: 'arraybuffer', signal })
      .then((res) => {
        if (res?.data) {
          const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          const reader = new FileReader();
          reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws);
            /* Update state */
            const csv = data?.toString()?.split('\n');
            setColumns(csv?.shift()?.split(','));
            setData(csv?.map((arr: string) => arr?.split(',')));
          };

          reader.readAsBinaryString(blob);
        }
      }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  async function handlePreview() {
    setLoading(true);
    let blob = await fetch(file.preview).then(r => r.blob());

    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws);
      /* Update state */
      const csv = data?.toString()?.split('\n');
      setColumns(csv?.shift()?.split(','));
      setData(csv?.map((arr: string) => arr?.split(',')));
    };

    reader.readAsBinaryString(blob);
    setLoading(false);
  };

  useEffect(() => {
    const abortController = new AbortController();

    fetcher && parseInt(file?.id?.toString()) === file?.id && fetchFile(abortController.signal);

    file?.preview && handlePreview();

    return () => { abortController.abort(); };
  }, [fetcher, file]);

  if (loading) {
    return <CircleLoader />;
  } else {
    return (
      <div className="csv">
        <table className="small transparent text-left">
          <thead>
            <tr>
              {columns?.map((name: string, key: number) =>
                <th key={`column_${key}`}>{name}</th>)}
            </tr>
          </thead>
          <tbody>
            {data?.map((line: string[], dataKey: number) =>
              <tr key={`data_${dataKey}`}>
                {line?.map((str: string, key: number) =>
                  <td key={`data_${dataKey}_${key}`}>{str}</td>)}
              </tr>)}
          </tbody>
        </table>
      </div>
    );
  }
}