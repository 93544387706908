export const showPrice = (input: string | number) => {
  const number = typeof input === "string" ? parseFloat(input) : input;
  if (isNaN(number)) {
    return 0;
  }

  const formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  });

  return formatter.format(number);
};
