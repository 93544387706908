import classNames from "classnames";
import { useEffect, useState } from "react";
import { computeIconStyle, IconStyleType } from "..";
import { Shape } from "../../../components/shapes";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { Grid } from "../../blocs";
import { formType } from "../../buttons";
import { IconButtonIcons, IconList } from "../../icons";

type Props = {
  children: any;
  size?: number;
  icon?: IconList;
  iconStyle?: IconStyleType;
  className?: string;
  form?: formType;
  iconColor?: CssVariablesType;
  shapeColor?: CssVariablesType;
};

export function H2({ children, size = 20, icon, iconColor = "text-soft", shapeColor = "blue-background", iconStyle = null, className = "" }: Props) {
  const [computedShapeColor, setComputedShapeColor] = useState(null);
  const [computedIconColor, setComputedIconColor] = useState(null);

  useEffect(() => {
    if (iconStyle) {
      let computed = computeIconStyle(iconStyle);
      setComputedShapeColor(computed.shapeColor);
      setComputedIconColor(computed.iconColor);
    }
  }, [iconStyle]);

  return (
    <Grid
      space={10}
      mediaQuery={0}
      template={icon ? `auto 1fr` : "1fr"}
      alignItems="center"
      className={classNames(!className?.includes("mb") && "mb-1", !className?.includes("mt") && "mt-4", className)}
    >
      {icon && (
        <Shape form="circle" color={computedShapeColor ?? shapeColor}>
          <IconButtonIcons icon={icon} size={size} color={computedIconColor ?? iconColor} />
        </Shape>
      )}
      <h2 className="mt-0 mb-0">{children}</h2>
    </Grid>
  );
}
