import { AxiosInstance } from "axios";
import { QuestionAttachmentType, QuestionExpertAttachmentType } from "../../../../utilities/redux/types";
import { useEffect, useState } from "react";
import { CircleLoader } from "../../loaders";

type Props = {
  file: QuestionAttachmentType | QuestionExpertAttachmentType;
  app: "company" | "expert" | "admin";
  senderType: "" | "_from_company" | "_from_expert";
  fetcher: AxiosInstance;
};

export function CsvPreview({ file, app, senderType = "", fetcher }: Props) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFile = (signal: AbortSignal) => {
    setLoading(true);
    fetcher
      .get(`${app}/questionAttachment/questionAttachment${senderType}/${file?.id}`, { signal })
      .then((res) => {
        if (res?.data) {
          const data = res?.data.split('\r\n');
          setColumns(data?.shift()?.split(','));

          setData(data?.map((arr: string) => arr?.split(',')));
        }
      }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  async function handlePreview() {
    setLoading(true);
    let blob = await fetch(file.preview).then(r => r.blob());

    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = evt.target.result?.toString()?.split('\r\n');
      setColumns(data?.shift()?.split(','));
      setData(data?.map((arr: string) => arr?.split(',')));

    };

    reader.readAsText(blob);
    setLoading(false);
  };

  useEffect(() => {
    const abortController = new AbortController();

    fetcher && parseInt(file?.id?.toString()) === file?.id && fetchFile(abortController.signal);

    file?.preview && handlePreview();

    return () => { abortController.abort(); };
  }, [fetcher, file]);

  if (loading) {
    return <CircleLoader />;
  } else {
    return (
      <div className="csv">
        <table className="small transparent text-left">
          <thead>
            <tr>
              {columns?.map((name: string, key: number) =>
                <th key={`column_${key}`}>{name}</th>)}
            </tr>
          </thead>
          <tbody>
            {data?.map((line: string[], dataKey: number) =>
              <tr key={`data_${dataKey}`}>
                {line?.map((str: string, key: number) =>
                  <td key={`data_${dataKey}_${key}`}>{str}</td>)}
              </tr>)}
          </tbody>
        </table>
      </div>
    );
  }
}