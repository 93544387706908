import classNames from "classnames";
import { Shape } from "../../../components/shapes";
import { Grid } from "../../blocs";

import { useEffect, useState } from "react";
import { computeIconStyle, IconStyleType } from "..";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { IconButtonIcons, IconList } from "../../icons";

type Props = {
  children: any;
  size?: number;
  icon?: IconList;
  iconStyle?: IconStyleType;
  iconColor?: CssVariablesType;
  shapeColor?: CssVariablesType;
  className?: string;
  numero?: number;
};

export function H3({ children, size = 20, icon, iconColor = "text-soft", shapeColor = "blue-background", className = "", iconStyle = null, numero }: Props) {
  const [computedShapeColor, setComputedShapeColor] = useState(null);
  const [computedIconColor, setComputedIconColor] = useState(null);

  useEffect(() => {
    if (iconStyle) {
      let computed = computeIconStyle(iconStyle);
      setComputedShapeColor(computed.shapeColor);
      setComputedIconColor(computed.iconColor);
    }
  }, [iconStyle]);

  return (
    <Grid
      space={8}
      mediaQuery={0}
      template={icon || numero ? `auto 1fr` : "1fr"}
      alignItems="center"
      className={classNames(!className.includes("mb") && "mb-2", !className.includes("mt") && "mt-2", className)}
    >
      {numero && (
        <div
          style={{
            width: "30px",
            height: "30px",
            background: "var(--color-active)",
            color: "white",
            fontSize: "1.05rem",
            fontWeight: "700",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {numero}
        </div>
      )}
      {icon && (
        <Shape form="circle" color={computedShapeColor ?? shapeColor}>
          <IconButtonIcons icon={icon} size={size} color={computedIconColor ?? iconColor} />
        </Shape>
      )}
      <h3 className="mt-0 mb-0 pb-0 pt-0 row a-center">{children}</h3>
    </Grid>
  );
}
