import { showPrice } from "../../../../utilities/tools/prices";
import { Grid } from "../../blocs";
import { MessageBox } from "../../infos";

type Props = {
  json: any;
};

export function InvoicePreview({ json }: Props) {
  return (
    <div className="column">
      <div id="billing-total" className="mt-0">
        <table className="mb-2 mt-1">
          <thead>
            <tr>
              <th className="pt-2 pb-2">Quantité</th>
              <th>Désignation</th>
              <th className="text-right">PU HT</th>
              <th className="text-right">Remise HT</th>
              <th className="text-right">Montant HT</th>
              <th className="text-right">Montant TTC</th>
            </tr>
          </thead>
          <tbody>
            <tr className="subtile fs-100 fw-500">
              <td colSpan={6} className="text-uppercase">
                Tarification récurrente soumise au forfait plancher de{" "}
                {json?.details?.recurrent?.total?.pricingFloor ? <strong>{showPrice(json?.details?.recurrent?.total?.pricingFloor)}</strong> : ""}{" "}
                {json?.details?.recurrent?.total?.prorataAmount ? (
                  <span>
                    ainsi qu&apos;à un taux de prorata de <strong>{json?.details?.recurrent?.total?.prorataPercent?.toFixed(1)} %</strong>
                  </span>
                ) : (
                  ""
                )}
              </td>
            </tr>
            {json?.details?.recurrent.items?.map((item: any) => {
              return (
                <tr key={item?.legend}>
                  <td datatype="Quantité" className="description">
                    {item?.number}
                  </td>
                  <td datatype="Désignation" className="description">
                    {item?.legend}
                  </td>
                  <td datatype="PU HT" className="text-right">
                    {showPrice(item?.discount?.amountType === "fixed_rate" ? item?.discount?.amount : item?.amount)}
                  </td>
                  <td datatype="Remise HT" className="text-right">
                    {showPrice(item?.discountHT)}
                  </td>
                  <td datatype="Montant HT" className="text-right">
                    {showPrice(item?.withDiscountHT)}
                  </td>
                  <td datatype="Montant TTC" className="text-right">
                    {showPrice(item?.TTC)}
                  </td>
                </tr>
              );
            })}

            <tr className="fs-100 fw-700">
              <td colSpan={2}>
                <strong>Sous-total Récurrent</strong>
              </td>
              <td className="text-right"></td>
              <td datatype="Remise HT" className="text-right">
                <strong> {showPrice(json?.details?.recurrent.total.discountHT ? showPrice(json?.details?.recurrent?.total?.discountHT) : 0)}</strong>
              </td>
              <td datatype="Montant HT" className="text-right">
                <strong>{showPrice(json?.details?.recurrent?.total?.withDiscountHT)}</strong>
              </td>
              <td datatype="Montant TTC" className="text-right">
                <strong> {showPrice(json?.details?.recurrent?.total?.TTC)}</strong>
              </td>
            </tr>

            {/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}

            <tr className="subtile fs-100">
              <td colSpan={6}>TARIFICATION UNIQUE</td>
            </tr>

            {json?.details?.charge.items?.map((item, key) => {
              return (
                <tr key={key}>
                  <td datatype="Quantité" className="description">
                    {item?.number}
                  </td>
                  <td datatype="Désignation" className="description">
                    {item?.legend}
                  </td>
                  <td datatype="PU HT" className="text-right">
                    {showPrice(item?.withoutDiscountHT)}
                  </td>
                  <td datatype="Remise HT" className="text-right">
                    {showPrice(item?.discountHT)}
                  </td>
                  <td datatype="Montant HT" className="text-right">
                    {showPrice(item?.withDiscountHT)}
                  </td>
                  <td datatype="Montant TTC" className="text-right">
                    {showPrice(item?.TTC)}
                  </td>
                </tr>
              );
            })}

            <tr className="fs-100 fw-700">
              <td colSpan={2}>
                <strong>Sous-total tarification unique</strong>
              </td>
              <td className="text-right"></td>
              <td datatype="Remise HT" className="text-right">
                <strong>{showPrice(json?.details?.charge?.total?.discountHT ? showPrice(json?.details?.charge?.total?.discountHT) : 0)}</strong>
              </td>
              <td datatype="Montant HT" className="text-right">
                <strong> {showPrice(json?.details?.charge?.total?.withDiscountHT)}</strong>
              </td>
              <td datatype="Montant TTC" className="text-right">
                <strong> {showPrice(json?.details?.charge?.total?.TTC)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Grid template="1fr 1fr 2fr">
        <span></span>
        <span></span>
        <div className="tile gray-light">
          <table className="transparent">
            <tbody>
              <tr className="fw-600 fs-100">
                <td>Total HT</td>
                <td className="text-right">{showPrice(json?.total?.HT)}</td>
              </tr>
              <tr className="fw-600 fs-100 ">
                <td>TVA ({json?.tax_rate ?? 20}%)</td>
                <td className="text-right mt-1">{showPrice(json?.total?.TVA)}</td>
              </tr>
              <tr className="fw-700 fs-130">
                <td>
                  <h3 className="mb-0 mt-1">Total TTC</h3>
                </td>
                <td className="text-right">
                  <h3 className="mb-0 mt-1">{showPrice(json?.total?.TTC)}</h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
      {json?.details.recurrent?.total?.prorataAmount && json?.details.recurrent?.total?.prorataAmount > 0 ? (
        <MessageBox className="mt-5" maxWidthActive={false}>
          Vous bénéficiez d&apos;un prorata d&apos;un montant de <strong>{showPrice(json?.details?.recurrent?.total?.prorataAmount)}</strong> car cette facture
          est la première générée par Syndemos et que le début de votre souscription date du 05/04/2024
        </MessageBox>
      ) : (
        ""
      )}
    </div>
  );
}
