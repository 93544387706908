import classNames from "classnames";
import React, { HTMLAttributes, useRef, useState } from "react";
import { BsFileArrowUp } from "../../../react-icons/bs";


interface Props extends HTMLAttributes<HTMLButtonElement> {
  onChangeCb: (arg: any) => void;
  onClickCb?: () => void;
  acceptList?: string;
};

export function DropZoneWrapper({
  children,
  onChangeCb,
  onClickCb,
  style,
  acceptList = "text/plain",
  ...props
}: Props) {
  const [isDragin, setIsDragin] = useState<boolean>(false);

  const inputRef: any = useRef(null);

  const onDragEnter = (e: any) => {
    e.preventDefault();
    setIsDragin(true);
  };
  const onDragLeave = (e: any) => {
    e.preventDefault();
    setIsDragin(false);
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
    setIsDragin(true);
  };

  const onDragDrop = (e: any) => {
    e.preventDefault();
    onChange(e.dataTransfer.files[0]);
    setIsDragin(false);
  };

  const onChange = (files: any) => {
    onChangeCb(files);
  };

  return (
    <button
      {...props}
      className={classNames("drop-zone-container clickable", props.className)}
      onClick={(e) => {
        inputRef.current?.click();
        props.onClick && props.onClick(e);
      }}
      style={{ ...style, position: "relative" }}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDragDrop}
      onDragOver={onDragOver}
    >
      <input
        ref={inputRef}
        hidden
        type="file"
        accept={acceptList}
        onChange={(e: any) => onChange(e?.target?.files[0])}
        onClick={
          onClickCb
            ? () => {
              /// delete old value from input
              inputRef.current.value = null;
              onClickCb();
            }
            : () => { }
        }
      />
      <div style={{ position: "absolute", inset: 0 }} className={classNames("cache", isDragin === true ? "visible" : "hidden")}>
        <BsFileArrowUp />
        <p>Ajouter le(s) fichier(s) en le relachant</p>
      </div>
      {children}
    </button>
  );
}
