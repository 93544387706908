"use client";
import { useEffect, useRef, useState } from "react";
import { FaEdit } from "../../../react-icons/fa";
import { IoDocumentOutline, IoDocumentTextOutline } from "../../../react-icons/io5";
import { bitToMo } from "../../../../utilities/tools/converter";
import classNames from "classnames";
import { H3 } from "../../titles";

type Props = {
  filesAccepted?: string;
  legend?: string;
  onSubmit: (param: Array<any>) => void;
};

export function DropZoneText({ onSubmit, legend = "Déposer votre fichier FEC", filesAccepted = "text/plain" }: Props) {
  const [isDragin, setIsDragin] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>(null);
  const dropzoneRef = useRef(null);
  const inputRef: any = useRef(null);

  const onDragEnter = (e: any) => {
    e.preventDefault();
    setIsDragin(true);
  };
  const onDragLeave = (e: any) => {
    e.preventDefault();
    setIsDragin(false);
  };
  const onDragOver = (e: any) => {
    e.preventDefault();
    setIsDragin(true);
  };
  const onDragDrop = (e: any) => {
    e.preventDefault();
    onChange(e.dataTransfer.files);
    setIsDragin(false);
  };

  const onChange = (previews: []) => {
    setPreview(Array.from([...previews]));

    onSubmit(previews);
  };

  const onClick = () => {
    inputRef?.current?.click();
  };

  const editFiles = () => {
    onClick();
  };

  return (
    <div className={classNames("dropzone mt-1 mb-2 tile br-3", preview === null && "clickable")}>
      <H3 icon="file" size={18} className="p-0 mt-0 mb-1" iconStyle="info-light">
        Fichier correspondant
      </H3>

      <div
        className={classNames("drop-zone-container", preview === null && "clickable")}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDragDrop}
        onDragOver={onDragOver}
      >
        <input ref={inputRef} hidden type="file" accept={filesAccepted} onChange={(e: any) => onChange(e?.target?.files)} />
        {preview === null && (
          <div className={classNames("drop-zone tile transparent", isDragin === true && "dragin")} ref={dropzoneRef} onClick={() => onClick()}>
            <div className="icon-inactive mt-1">
              <IoDocumentTextOutline size={45} color={"var(--color-text)"} />
            </div>
            <strong className="text-center">{legend}</strong>
            <p className="text-center">Faites un glisser-déposer ou cliquer sur le bloc pour uploader le fichier</p>
            <em className="text-center subtitle mb-1 mt-0">Taille maximale 128 Mo</em>
          </div>
        )}
        {!!preview && (
          <div className={classNames("fec", "drop-zone", isDragin === true && "dragin")}>
            <div className="icon">
              <IoDocumentOutline size={35} className="p-3" />
            </div>
            <div className="file">
              <div className="name">{preview[0].name}</div>
              <div className="size">{bitToMo(preview[0].size)} Mo</div>
              <div className="button clickable button" onClick={() => editFiles()}>
                <FaEdit size={20} />
                Modifier le fichier
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
